html {
  font-size: 14px;
  font-family: Roboto;
}

* {
  margin: 0;
  padding: 0;
}

#root {
  /* width: 100vw;
  height: 100vh; */
}